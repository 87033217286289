<template>
  <div class="bidding-info-page">
    <div class="sub-banner">
      <div class="bgImg">
        <div class="sub-txt">
          <h6>{{getMenu.tips}}</h6>
          <p>{{getMenu.name}}</p>
        </div>
      </div>
    </div>
    <template v-if="bidInfo">
      <div class="cont">
        <div class="info-title">{{ bidInfo.name }}</div>
        <div class="info-container">
          <div class="main">
            <div class="block">
              <p class="label">项目概况</p>
              <div class="block-info-box">
                <div class="block-info-item">
                  <div>
                    <span class="title">招标方式</span>
                    <span>{{ bidInfo.biddingTypeName }}</span>
                  </div>
                  <div>
                    <span class="title">项目区域</span>
                    <span>{{ bidInfo.areaName }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="block">
              <p class="label">招标人</p>
              <div class="block-info-box">
                <div class="bid-person">
                  <div>
                    <span class="title">招标人</span>
                    <span>{{ bidInfo.biddingPeople }}</span>
                  </div>
                  <div>
                    <span class="title">地址</span>
                    <span>{{ bidInfo.biddingArea }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="block">
              <p class="label">公告内容</p>
              <div class="block-info-box">
                <div class="notice-cont" v-html="bidInfo.info"></div>
              </div>
            </div>

            <a
              :href="bidInfo.servicePath + bidInfo.attachment"
              :download="bidInfo.name"
              @click=""
              >{{ bidInfo.name + "." + bidInfo.attachment.split(".")[1] }}</a
            >
          </div>
          <div class="sub-right">
            <div :style="{ position: position }">
              <div class="sign-up">
                <a @click="wantSign">我要报名</a>
              </div>
              <div class="timeline">
                <div class="title">时间汇总</div>
                <div class="child-list">
                  <div class="dot">
                    <h4>报名开始时间</h4>
                    <div>
                      <span>{{bidInfo.beginTime}}</span>
                    </div>
                  </div>
                  <div class="dot">
                    <h4>报名结束时间</h4>
                    <div>
                      <span>{{bidInfo.applyEndTime}}</span>
                    </div>
                  </div>
                  <div class="dot">
                    <h4>开标时间</h4>
                    <div>
                      <span>{{bidInfo.endTime}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <Modal
      title="我要报名"
      v-model="signShow"
      :scrollable="true"
      @on-visible-change="modalShow"
    >
      <Form
        ref="biddingInfo"
        :model="biddingInfo"
        :rules="rules"
        :label-width="110"
      >
        <FormItem label="公司名称" prop="name">
          <Input style="width: 300px" v-model="biddingInfo.name"></Input>
        </FormItem>
        <FormItem label="联系人" prop="linkman">
          <Input style="width: 300px" v-model="biddingInfo.linkman"></Input>
        </FormItem>
        <FormItem label="联系电话" prop="linktel">
          <Input style="width: 300px" v-model="biddingInfo.linktel"></Input>
        </FormItem>
        <!-- <FormItem label="招标名称" prop="describes">
          <Input style="width: 300px" v-model="biddingInfo.describes"></Input>
        </FormItem> -->
        <FormItem label="资料打包上传" prop="attachment">
          <upload-file
            ref="uploadResume"
            :max="1"
            v-model="biddingInfo.attachment"
          ></upload-file>
        </FormItem>
      </Form>
      <p slot="footer" style="text-align: center">
        <Button type="primary" :loading="loading" @click="submit">提交</Button>
        <Button @click="() => (this.signShow = false)">取消</Button>
      </p>
    </Modal>
  </div>
</template>

<script>
import uploadFile from "@components/upload/upload_file";
export default {
  name: "",
  components: {
    uploadFile,
  },
  data() {
    return {
      bidInfo: null,
      position: "relative",
      signShow: false,
      biddingInfo: {
        name: "",
        linkman: "",
        linktel: "",
        // describes: "",
        attachment: "",
      },
      rules: {
        name: [{ required: true, message: "请填写公司名字" }],
        linkman: [{ required: true, message: "请填写联系人名字" }],
        linktel: [{ required: true, message: "请填写联系电话" }],
        // describes: [{ required: true, message: "请填写招标名字" }],
        attachment: [
          { required: true, message: "请上传报名表", trigger: "blur" },
        ],
      },
      loading: false,
    };
  },
  computed: {
    getMenu() {
      let menu = this.$store.state.permission.menu.filter(
        (item) => item.router == "bidding"
      )[0];
      return menu;
    },
  },
  methods: {
    getInfo() {
      this.$post(this.$api.BIDDING.VIEW, {
        id: this.$route.query.bidId,
      }).then((res) => {
        // console.log(res);
        this.bidInfo = res;
      });
    },
    handleScroll() {
      let scrollTop =
        document.body.scrollTop || document.documentElement.scrollTop;
      if (scrollTop >= 484) {
        this.position = "fixed";
      } else {
        this.position = "relative";
      }
    },
    wantSign() {
      this.signShow = true;
    },
    submit() {
      this.$refs.biddingInfo.validate().then((res) => {
        if (!res) return;
        this.loading = true;
        let params = { ...this.biddingInfo };
        params.biddingId = this.$route.query.bidId
        params.biddingName = this.$route.query.biddingName
        let img = this.biddingInfo.attachment
        let n = img.indexOf('/')
        for (let i = 0; i < 2; i++) {
          n = img.indexOf('/', n+1)
        }
        let pic = img.substring(n + 1)
        params.attachment = pic
        this.$post("tender/add", params)
          .then(() => {
            this.$Message.success("报名成功");
            this.signShow = false;
          })
          .finally(() => {
            this.loading = false;
          });
      });
    },
    modalShow(visible) {
      if (visible) return;
      this.biddingInfo = {
        name: "",
        linkman: "",
        linktel: "",
        // describes: "",
        attachment: "",
      };
      this.$refs.biddingInfo.resetFields();
      this.$refs.uploadResume.clearFiles();
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true);
    this.getInfo();
  },
};
</script>

<style lang="less" scoped>
.bidding-info-page {
  width: 100%;
  .sub-banner {
    height: 300px;
    position: relative;

    .bgImg {
      width: 100%;
      height: 100%;
      background: url(../../../public/imgs/backgroundImg/01.jpg) no-repeat
        center center;

      .sub-txt {
        text-align: center;
        width: 1200px;
        margin: 0 auto;
        padding-top: 90px;

        h6 {
          font-family: Arial;
          font-size: 40px;
          font-weight: bold;
          color: #003f3c;
          text-transform: uppercase;
        }

        p {
          font-size: 30px;
          color: #003f3c;
          line-height: 40px;
        }
      }
    }
  }
  .cont {
    width: 1200px;
    margin: 0 auto;
  }
  .info-title {
    padding: 20px 0;
    line-height: 36px;
    font-size: 24px;
    color: #333;
  }
  .info-container {
    width: 100%;
    border: 1px solid #eee;
    border-top: 2px solid #0075e2;
    margin-bottom: 50px;
    display: flex;
    overflow: hidden;
    // position: relative;
    .main {
      padding: 20px;
      width: 900px;
      .block {
        margin-bottom: 50px;
        .label {
          border-bottom: 1px solid #eee;
          padding: 10px 0;
        }
        .block-info-box {
          margin: 20px 0;
          .block-info-item {
            display: flex;
            justify-content: space-between;
            > div {
              padding: 0 20px;
              .title {
                padding-right: 20px;
                color: #999;
              }
            }
          }
          .bid-person {
            > div {
              padding: 0 20px;
              &:not(:last-child) {
                padding-bottom: 10px;
              }
              .title {
                padding-right: 20px;
                color: #999;
              }
            }
          }
          .notice-cont {
            max-height: 700px;
            overflow: auto;
            &::-webkit-scrollbar {
              display: none;
            }
          }
        }
      }
    }
    .sub-right {
      width: 220px;
      height: 500px;
      // float: right;
      // margin-top: 20px;
      margin-left: 70px;
      overflow: hidden;
      > div {
        top: 20px;
        // position: fixed;
      }
      .sign-up {
        a {
          width: 208px;
          transition: all linear 0.2s;
          background: #ee661b;
          padding-left: 25px;
          display: block;
          text-align: center;
          line-height: 40px;
          color: #fff;
          font-size: 16px;
          &:hover {
            background: #d3540e;
          }
        }
      }
      .timeline {
        background: url(../../../public/imgs/icon/timeline.png) no-repeat 16px
          top;
        height: 480px;
        margin-top: 40px;
        width: 200px;
        position: relative;
        .title {
          position: absolute;
          background: url(../../../public/imgs/icon/time.png) no-repeat 0px top;
          height: 50px;
          width: 200px;
          padding-left: 50px;
          line-height: 50px;
          font-size: 16px;
        }
        .child-list {
          position: absolute;
          left: 0;
          top: 50px;
          .dot {
            background: url(../../../public/imgs/icon/timedot.png) no-repeat
              10px 0;
            padding-left: 50px;
            margin-bottom: 25px;
            width: 250px;
            h4 {
              font-size: 16px;
              color: #0075e2;
              font-weight: normal;
            }
            div {
              margin-top: 10px;
              font-size: 13px;
              color: #999;
            }
          }
        }
      }
    }
  }
}
</style>