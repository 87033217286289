<template>
  <div>
    <base-upload
      :on-exceeded-size="handleMaxSize"
      ref="base"
      @input="(val)=>{$emit('input',val)}"
      :value="value"
      :maxSize="maxSize"
      :max="max"
      :url="url"
    >
      <template v-slot:default="{uploadList}">
        <div style="max-width:100%" :key="index" v-for="(item,index) in uploadList">
          <template v-if="item.status === 'finished'">
            <p :title="item.name" class="file-name">
              {{item.name}}
              <Icon @click="handleRemove(item)" class="close-icon" type="md-close" color="red" />
            </p>
          </template>
          <template v-else>
            <Progress v-if="item.showProgress" :percent="item.percentage" hide-info></Progress>
          </template>
        </div>
      </template>
      <template #button>
        <Icon class="upload-button" type="md-cloud-upload" color="#446ba1" />
      </template>
    </base-upload>
  </div>
</template>
<script>
import baseUpload from "./base_upload";
import { baseUrl } from "@common/http/api";
import API from "@common/http/api";

function dataURItoBlob(base64Data) {
  let byteString;
  if (base64Data.split(",")[0].indexOf("base64") >= 0)
    byteString = window.atob(base64Data.split(",")[1]);
  else byteString = window.decodeURI(base64Data.split(",")[1]);
  let mimeString = base64Data
    .split(",")[0]
    .split(":")[1]
    .split(";")[0];
  let ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ia], { type: mimeString });
}

export default {
  components: {
    baseUpload
  },
  props: {
    value: {
      type: String,
      default: ""
    },
    max: Number,
    maxSize: Number
  },
  data() {
    return {
      url: baseUrl.URL + API.UPLOAD.LOGGED_IN
    };
  },
  methods: {
    clearFiles() {
      this.$refs.base.clearFiles();
    },
    handleRemove(file) {
      this.$refs.base.handleRemove(file);
    },
    handleMaxSize() {
      this.$Message.warning(`文件不要超过${this.maxSize}kb`);
    }
  }
};
</script>
<style lang="less" scoped>
.upload-button {
  font-size: 24px;
  cursor: pointer;
}
.file-name {
  background: rgba(204, 204, 204, 0.2);
  padding: 0 10px 0 5px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  .close-icon {
    position: absolute;
    right: 2px;
    top: 2px;
    cursor: pointer;
    display: none;
  }
  &:hover {
    .close-icon {
      display: block;
    }
  }
}
</style>
